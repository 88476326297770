import React from 'react';
import './style.css';

const daysOfTheWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
// let options = {
//   weekday: 'long',
//   month: 'long',
//   day: 'numeric',
// };

const today = new Date();

const tmpFirstDayOfMonth = new Date();
tmpFirstDayOfMonth.setDate(1);
const tmpFirstDayOfCalendar = new Date();
tmpFirstDayOfCalendar.setDate(1);
tmpFirstDayOfCalendar.setDate(-1 * (tmpFirstDayOfCalendar.getDay() - 1));

const Calendar = ({
  todos,
  setTodos,
  weather,
  onDateSelected,
}) => {
  const [firstDayOfMonth, setFirstDayOfMonth] = React.useState(tmpFirstDayOfMonth);
  const [firstDayOfCalendar, setFirstDayOfCalendar] = React.useState(tmpFirstDayOfCalendar);

  const onSetMonth = (offset) => {
    const newFirstDayOfMonth = new Date(firstDayOfMonth.getTime());
    newFirstDayOfMonth.setMonth(newFirstDayOfMonth.getMonth() + offset);

    const newFirstDayOfCalendar = new Date(newFirstDayOfMonth.getTime());
    newFirstDayOfCalendar.setDate(-1 * (newFirstDayOfCalendar.getDay() - 1));

    setFirstDayOfMonth(newFirstDayOfMonth);
    setFirstDayOfCalendar(newFirstDayOfCalendar);

  };

  const firstDay = new Date(firstDayOfCalendar.getTime());

  const prevMonth = new Date(firstDayOfMonth.getTime());
  prevMonth.setMonth(firstDayOfMonth.getMonth() - 1);

  const nextMonth = new Date(firstDayOfMonth.getTime());
  nextMonth.setMonth(firstDayOfMonth.getMonth() + 1);


  return (
    <div className="Calendar-container">
      <div className="Calendar-month">
        <button onClick={() => onSetMonth(-1)}>{'<< ' + prevMonth.toLocaleString('default', { month: 'long' })}</button>
        <span>{firstDayOfMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}</span>
        <button onClick={() => onSetMonth(1)}>{nextMonth.toLocaleString('default', { month: 'long' })} >></button>
      </div>
      <div className="Calendar-table">
        <table>
          <thead height="5%">
            <tr>
              {daysOfTheWeek.map((d, i) => (
                <td key={i}><div>{d}</div></td>
              ))}
            </tr>
          </thead>
          <tbody height="95%">
            {[1,2,3,4,5,6].map((date, weekIndex) => {
              return (
                <tr key={weekIndex}>
                  {daysOfTheWeek.map((day, dayIndex) => {
                    firstDay.setDate(firstDay.getDate() + 1);
                    let dayKey = firstDay.toISOString().split('T')[0];
                    // let dayLabel = firstDay.toLocaleString('default', options);
                    return (
                      <td key={weekIndex + '_' + dayIndex} className={firstDay.getFullYear() === today.getFullYear() && firstDay.getMonth() === today.getMonth() && firstDay.getDate() === today.getDate() ? 'today' : ''}>
                        <div>
                          <div className="day-header">
                            <div className="date-display">
                              <span>
                                {firstDay.getDate()}
                              </span>
                            </div>
                            <div className="weather-display">
                              {
                                firstDay.getMonth() === today.getMonth() &&
                                firstDay.getDate() >= today.getDate() &&
                                weather &&
                                weather[firstDay.getDate() - today.getDate()]}
                            </div>
                          </div>
                          <div className="day-body">
                            <div className="todos">
                              <div className="add-todo">
                                <button onClick={() => onDateSelected(dayKey)}>+</button>
                              </div>
                              <div className="todos-list">
                                {todos[dayKey] && todos[dayKey].map((t, i) => (
                                  <div key={i} className={`todo-item${t.done ? ' done' : ''}`}>
                                    <input type="checkbox" checked={t.done} id={`todo-item-${dayKey}-${i}`} onChange={() => {
                                      let newTodosDay = [
                                        ...todos[dayKey],
                                      ];
                                      newTodosDay[i] = {
                                        label: t.label,
                                        done: !t.done,
                                      };
                                      setTodos({
                                        ...todos,
                                        [dayKey]: [
                                          ...newTodosDay,
                                        ]
                                      });
                                    }} />
                                    <label htmlFor={`todo-item-${dayKey}-${i}`}>{t.label}</label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Calendar;
