import './style.css';

const Dialog = ({
  content,
  title,
  isOpen,
  onClose,
}) => {
  if (false === isOpen) {
    return null;
  }
  
  return (
    <div className="dialog">
      <div className="dialog-container">
        <div className="dialog-header">
          {title}
        </div>
        <div className="dialog-content">
          {content}
        </div>
        <button className="close" onClick={onClose}>&times;</button>
      </div>
    </div>
  );
};

export default Dialog;
