import React from 'react';
import './App.css';
import Calendar from 'Calendar';
import Dialog from 'Dialog';

function App() {
  let date = new Date();
  let options = {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  };
  let [city, setCity] = React.useState('');
  let [selectedDate, setSelectedDate] = React.useState(null);
  // let [locationData, setLocationData] = React.useState(null);
  let [weatherData, setWeatherData] = React.useState(null);
  let [newTodo, setNewTodo] = React.useState('');
  let [todos, setTodos] = React.useState(JSON.parse(localStorage.getItem('todos')));

  React.useEffect(() => {
    localStorage.setItem('todos', JSON.stringify(todos));
  }, [todos]);

  React.useEffect(() => {
    let fetchLocation = async () => {
      const locationResults = await fetch('https://ipapi.co/json/');
      if (locationResults.status === 200) {
        const newLocationData = await locationResults.json();
        // setLocationData(newLocationData);

        const weatherResults = await fetch(`https://api.openweathermap.org/data/2.5/onecall?lat=${newLocationData.latitude}&lon=${newLocationData.longitude}&appid=05ea18c4d8d9faff90e05c98a5fd5698&units=metric`);
        if (weatherResults.status === 200) {
          const newWeatherData = await weatherResults.json();
          setCity(newLocationData.city);
          setWeatherData(newWeatherData);
        } else {
          setCity(newLocationData.city);
        }
      }
    };

    fetchLocation();
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <span>{date.toLocaleString('default', options)}{weatherData ? ', ' + Math.round(weatherData.current.temp) + '°C feels like ' + Math.round(weatherData.current.feels_like) + '°C' : ''}</span>
        <section className="Location">
          <input className="location-input" readOnly type="search" placeholder="No Location Set" value={city} onChange={e => setCity(e.target.value)} />
        </section>
      </header>
      <section className="App-container">
        <Calendar
          todos={todos}
          setTodos={setTodos}
          weather={weatherData ? weatherData.daily.map(e => Math.round(e.temp.day) + '°C') : null}
          onDateSelected={selectedDate => setSelectedDate(selectedDate)}
        />
      </section>
      <footer className="App-footer">
        An Serverless Calendar - &copy; Copyright {date.getFullYear()} - <a href="https://www.sebastianrus.com">Sebastian Rus</a>
      </footer>
      <Dialog
        title={new Date(selectedDate).toLocaleString('default', options)}
        isOpen={!!selectedDate}
        content={selectedDate && (
          <div className="dialog-todos">
            {todos[selectedDate] && todos[selectedDate].map((t, i) => (
              <div key={i} className={`todo-item${t.done ? ' done' : ''}`}>
                <input type="checkbox" checked={t.done} id={`todo-item-${selectedDate}-${i}`} onChange={() => {
                  let newTodosDay = [
                    ...todos[selectedDate],
                  ];
                  newTodosDay[i] = {
                    label: t.label,
                    done: !t.done,
                  };
                  setTodos({
                    todos,
                    [selectedDate]: [
                      ...newTodosDay,
                    ]
                  });
                }} />
                <label htmlFor={`todo-item-${selectedDate}-${i}`}>{t.label}</label>
              </div>
            ))}
            <input
              autoFocus
              className="todo-input"
              type="text"
              placeholder="Enter new items and press enter"
              value={newTodo}
              onChange={e => setNewTodo(e.target.value)}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  let newTodosDay = [
                    ...(todos[selectedDate] ? todos[selectedDate] : []),
                    {
                      label: newTodo.trim(),
                      done: false,
                    },
                  ];
                  setTodos({
                    ...todos,
                    [selectedDate]: [
                      ...newTodosDay,
                    ]
                  });
                  setNewTodo('');
                }
              }}
              onKeyDown={e => {
                if (e.keyCode === 27) {
                  setNewTodo('');
                  setSelectedDate(null);
                }
              }}
            />
          </div>
        )}
        onClose={() => setSelectedDate(null)}/>
    </div>
  );
}

export default App;
